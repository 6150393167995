import React from 'react';
import styles from './DocumentContainer.module.css';

interface LayoutProps {
  children: React.ReactNode;
}

export default function DocumentContainer({ children }: LayoutProps) {
  return <article className={styles.document}>{children}</article>;
}
